<template>
  <div
    class="reg-form"
    :style="{backgroundImage: backImg}"
  >
    <!-- header  -->
    <b-row
      class="center-element"
      style="margin-right: -2px"
    >
      <b-col
        lg="8"
        class="header"
      >
        {{ $t('requestJoinSchool') }}
      </b-col>
    </b-row>

    <!-- registeration content -->
    <b-row class="center-element">
      <b-col
        lg="8"
        style="padding-left: 0;"
      >
        <b-card
          class="reg-card"
        >
          <g-form
            v-if="!formSubmitedSuccessfully"
            @submit="save"
          >

            <!-- steps section  -->
            <b-row
              class="reg-steps center-element mb-3 mt-1"
            >
              <div
                :class="currentStep === stepsEnum.guardianSection ? 'active-step': 'nonActive-step'"
              >
                {{ $t('step', {stepNo: '1'}) + ' : ' + $t('guardianData') }}
              </div>
              <span />
              <div
                :class="currentStep === stepsEnum.branchSection ? 'active-step': 'nonActive-step'"
              >
                {{ $t('step', {stepNo: '2'}) + ' : ' + $t('schoolWantToJoin') }}
              </div>
              <span />
              <div
                :class="currentStep === stepsEnum.studentSection ? 'active-step': 'nonActive-step'"
              >
                {{ $t('step', {stepNo: '3'}) + ' : ' + $t('studentData') }}
              </div>
            </b-row>

            <!-- guardian section  -->
            <b-row
              v-if="currentStep === stepsEnum.guardianSection"
              class="center-element flex-column"
            >
              <b-col md="5">
                <g-field
                  :value.sync="selectedItem.guardianName"
                  label-text="arabicName"
                />
                <span
                  v-show="!selectedItem.guardianName"
                  class="guardian-hint"
                > {{ $t('arabicName') + ' '+ $t('required') }}
                </span>
              </b-col>
              <b-col
                md="5"
                class="mt-50 mb-2"
              >
                <label class="main-font-size mb-75">
                  {{ $t("contanctMethodForRegisterationUpdates") }}
                </label>
                <b-form-radio-group
                  v-model="selectedItem.contactWithMobile"
                  :options="optionsContantMethods"
                  size="md"
                  name="radios-conact-methods"
                />
              </b-col>
              <b-col md="5">
                <g-field
                  v-show="selectedItem.contactWithMobile"
                  :value.sync="helper.guardianMobile"
                  label-text="mobile"
                  type="number"
                  name="scalesCode"
                  rules="min:10|max:10"
                />
                <g-field
                  v-show="!selectedItem.contactWithMobile"
                  :value.sync="helper.guardianEmail"
                  label-text="email"
                  rules="email"
                />
                <span
                  v-show="!helper.guardianMobile && !helper.guardianEmail"
                  class="guardian-hint"
                > {{ `${this.$t(selectedItem.contactWithMobile ? 'mobile' : 'email')} ${this.$t('required')}` }}</span>
              </b-col>
            </b-row>

            <!-- clientBranches Section -->
            <b-row
              v-if="currentStep === stepsEnum.branchSection"
              class="center-element flex-column"
            >
              <b-col
                v-for="branch in clientBranches"
                :key="branch"
                md="5"
                class="d-flex mb-1"
              >
                <b-form-radio
                  :id="branch.arabicName"
                  v-model="selectedItem.branchId"
                  :name="branch.arabicName"
                  :value="branch.id"
                  class="variant-primary"
                />
                <label
                  class="main-font-size ml-25 mr-1"
                  :for="branch.arabicName"
                > {{ branch.arabicName }}
                </label>
              </b-col>
            </b-row>

            <!-- arabic and english Name Section -->
            <b-row
              v-if="currentStep === stepsEnum.studentSection"
              class="mb-1"
            >
              <!-- arabic name  -->
              <b-col md="6">
                <g-field
                  :value.sync="selectedItem.arabicName"
                  rules="required"
                  label-text="arabicName"
                />
              </b-col>
              <!-- english name  -->
              <b-col md="6">
                <g-field
                  ref="englishName"
                  :value.sync="selectedItem.englishName"
                  label-text="englishName"
                />
              </b-col>
            </b-row>

            <!-- personal inforamtions section -->
            <b-tabs
              v-if="currentStep === stepsEnum.studentSection"
              v-model="tabIndex"
              content-class="pt-2"
              pills
              fill
            >
              <b-tab
                :title="$t('personalData')"
                :active="tabIndex === studentInfoEnum.personalData"
              >
                <b-card>
                  <b-row>
                    <!-- birthDate  -->
                    <b-col md="3">
                      <g-picker
                        :value.sync="selectedItem.birthDate"
                        label-text="birthDate"
                        name="birthDate"
                      />
                    </b-col>

                    <!-- registrationDate  -->
                    <b-col md="3">
                      <g-picker
                        :value.sync="selectedItem.registrationDate"
                        label-text="registrationDate"
                        name="registrationDate"
                      />
                    </b-col>

                    <!-- identityNumber -->
                    <b-col md="3">
                      <g-field
                        :value.sync="selectedItem.identityNumber"
                        label-text="IDNumber"
                      />
                    </b-col>

                    <!-- mobileNumber -->
                    <b-col md="3">
                      <g-field
                        id="phone"
                        rules="max:10|min:10|required"
                        :value.sync="selectedItem.mobileNumber"
                        name="scalesCode"
                        type="number"
                        label-text="phone"
                      />
                    </b-col>

                    <!-- noorId  -->
                    <b-col md="3">
                      <g-field
                        :value.sync="selectedItem.noorId"
                        label-text="noorId"
                        :rules="{ required: selectedItem.registeredInNoor }"
                      />
                    </b-col>

                    <!-- gender -->
                    <b-col md="3">
                      <b-form-group>
                        <g-field
                          :value.sync="selectedItem.gender"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          label-text="gender"
                          field="select"
                          name="gender"
                          :options="genders"
                          label="arabicName"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- nationality -->
                    <b-col md="3">
                      <b-form-group>
                        <g-field
                          field="select"
                          label="arabicName"
                          label-text="nationality"
                          name="nationality"
                          rules="required"
                          :value.sync="selectedItem.nationalityId"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          :options="nationalities"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- nameOfTheLastSchool  -->
                    <b-col md="3">
                      <g-field
                        :value.sync="selectedItem.nameOfTheLastSchool"
                        label-text="nameOfTheLastSchool"
                      />
                    </b-col>

                    <!-- noor registeration check -->
                    <b-col md="2">
                      <label
                        class="main-font-size"
                        style="margin-bottom: 7px"
                        for="registeredInNoor"
                      >
                        {{ $t("registeredInNoor") }}
                      </label>
                      <b-form-group>
                        <b-form-checkbox
                          v-model="selectedItem.registeredInNoor"
                          class="custom-control-primary"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- bus subscription -->
                    <b-col
                      md="4"
                      class="mb-50"
                    >
                      <label class="main-font-size">
                        {{ $t("transferRegistration") }}
                      </label>
                      <b-form-radio-group
                        v-model="selectedItem.hasBus"
                        button-variant="outline-primary"
                        :options="optionsRadio"
                        size="md"
                        name="radios-btn-default"
                      />
                    </b-col>

                    <!-- address of bus land -->
                    <b-col
                      v-show="selectedItem.hasBus === true"
                      md="3"
                    >
                      <g-field
                        :value.sync="selectedItem.detailedAddress"
                        label-text="detailedAddress"
                        :rules="{ required: selectedItem.hasBus }"
                      />
                    </b-col>

                    <!-- bus Subscription Category   -->
                    <b-col
                      v-show="selectedItem.hasBus === true"
                      md="3"
                    >
                      <b-form-group>
                        <g-field
                          :value.sync="selectedItem.busSubscriptionCategory"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          label-text="busSubscriptionCategory"
                          field="select"
                          name="busSubscriptionCategory"
                          :options="busSubscriptionCategory"
                          label="arabicName"
                          :rules="{ required: selectedItem.hasBus }"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- notes -->
                  <b-row>
                    <b-col md="12">
                      <b-form-group :label="$t('notes')">
                        <b-form-textarea
                          id="textarea"
                          v-model="selectedItem.notes"
                          label-text="Notes"
                          rows="3"
                          max-rows="6"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>

              <!-- educational inforamtions section -->
              <b-tab
                :title="$t('stageData')"
                :active="tabIndex === studentInfoEnum.educationalData"
              >
                <b-card>
                  <b-row>
                    <!-- stage -->
                    <b-col md="6">
                      <b-form-group>
                        <g-field
                          v-model="selectedItem.stageId"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          field="select"
                          name="stages"
                          :options="stages"
                          :label="isRight ? 'arabicName' : 'englishName'"
                          label-text="stage"
                          :rules="{ required: tabIndex === studentInfoEnum.educationalData }"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- row -->
                    <b-col md="6">
                      <b-form-group>
                        <g-field
                          v-model="selectedItem.rowId"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          field="select"
                          name="row"
                          :options="rows"
                          :label="isRight ? 'arabicName' : 'englishName'"
                          label-text="row"
                          :rules="{ required: tabIndex === studentInfoEnum.educationalData }"
                          :filter-fn="(item) => item.stageId === selectedItem.stageId"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>

              <!-- medical and psychological information Section  -->
              <b-tab
                :title="$t('Medicalandpsychologicaldataofthestudent')"
                :active="tabIndex === studentInfoEnum.healthData"
              >
                <b-card>
                  <b-row>
                    <!-- religion -->
                    <b-col md="4">
                      <b-form-group>
                        <g-field
                          :value.sync="selectedItem.religionId"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          label-text="religion"
                          field="select"
                          name="religions"
                          :options="religions"
                          label="arabicName"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- childLivesWith -->
                    <b-col md="4">
                      <b-form-group>
                        <g-field
                          :value.sync="selectedItem.childLivesWith"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          label-text="childLivesWith"
                          field="select"
                          name="childLivesWith"
                          :options="childLivesWith"
                          label="arabicName"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- maritalStatusParentsId -->
                    <b-col md="4">
                      <b-form-group>
                        <g-field
                          :value.sync="selectedItem.maritalStatusParentsId"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          label-text="maritalStatusForParents"
                          field="select"
                          name="maritalStatus"
                          :options="maritalStatus"
                          label="arabicName"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- roleOfGrandparents -->
                    <b-col md="4">
                      <b-form-group>
                        <g-field
                          :value.sync="selectedItem.roleOfGrandparents"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          label-text="roleOfGrandparents"
                          field="select"
                          name="RoleOfGrandparents"
                          :options="roleOfGrandparents"
                          label="arabicName"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- illnessHistoryInFamily -->
                    <b-col md="4">
                      <b-form-group>
                        <g-field
                          :value.sync="selectedItem.illnessHistoryInFamily"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          label-text="illnessHistoryInFamily"
                          field="select"
                          name="illnessHistoryInFamily"
                          :options="illnessHistoryInFamily"
                          label="arabicName"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- suffersFromAnAllergy -->
                    <b-col md="4">
                      <b-form-group>
                        <g-field
                          :value.sync="selectedItem.suffersFromAnAllergy"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          label-text="suffersFromAnAllergy"
                          field="select"
                          name="suffersFromAnAllergy"
                          :options="suffersFromAnAllergy"
                          label="arabicName"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- describeChild -->
                    <b-col md="4">
                      <b-form-group>
                        <g-field
                          :value.sync="selectedItem.describeChild"
                          :dir="isRight ? 'rtl' : 'ltr'"
                          label-text="describeChild"
                          field="select"
                          name="describeChild"
                          :options="describeChild"
                          label="arabicName"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- behaviorsWishToGetRidOf -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.behaviorsWishToGetRidOf"
                        label-text="behaviorsWishToGetRidOf"
                      />
                    </b-col>

                    <!-- wentToAPsychologistOrBehaviorist -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.wentToAPsychologistOrBehaviorist"
                        label-text="heWentToPsychologistOrBehaviorist"
                      />
                    </b-col>

                    <!-- punishmentOfTheChildWhenWrong -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.punishmentOfTheChildWhenWrong"
                        label-text="punishmentOfTheChildWhenWrong"
                      />
                    </b-col>

                    <!-- numberOfBrothers  -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.numberOfBrothers"
                        label-text="numberOfBrothers"
                      />
                    </b-col>

                    <!-- childArrangement -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.childArrangement"
                        label-text="childArrangement"
                      />
                    </b-col>

                    <!-- childSkillsAndTendencies -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.childSkillsAndTendencies"
                        label-text="childSkillsAndTendencies"
                      />
                    </b-col>

                    <!-- academicLevelAndAchievement -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.academicLevelAndAchievement"
                        label-text="academicLevelAndAchievement"
                      />
                    </b-col>

                    <!-- participatesInGroupGames -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.participatesInGroupGames"
                        label-text="participatesInGroupGames"
                      />
                    </b-col>

                    <!-- favoriteSports -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.favoriteSports"
                        label-text="favoriteSports"
                      />
                    </b-col>

                    <!-- favoritePlace -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.favoritePlace"
                        label-text="favoritePlace"
                      />
                    </b-col>

                    <!-- relationshipWitFriendsAndBrother -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.relationshipWitFriendsAndBrother"
                        label-text="relationshipWitFriendsAndBrother"
                      />
                    </b-col>

                    <!-- firstReactionToBeingPressured -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.firstReactionToBeingPressured"
                        label-text="firstReactionToBeingPressured"
                      />
                    </b-col>

                    <!-- firstPersonChildTurnsToWhenAngry -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.firstPersonChildTurnsToWhenAngry"
                        label-text="firstPersonChildTurnsToWhenAngry"
                      />
                    </b-col>

                    <!-- suffersFromDiseases  -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.suffersFromDiseases"
                        label-text="suffersFromDiseases"
                      />
                    </b-col>

                    <!-- healthReasonThatInterferesWithPerformance -->
                    <b-col md="4">
                      <g-field
                        :value.sync="
                          selectedItem.healthReasonThatInterferesWithPerformance
                        "
                        label-text="healthReasonThatInterferesWithPerformance"
                      />
                    </b-col>

                    <!-- receivesTreatmentPeriodicallyOrContinuously -->
                    <b-col md="4">
                      <g-field
                        :value.sync="
                          selectedItem.receivesTreatmentPeriodicallyOrContinuously
                        "
                        label-text="receivesTreatmentPeriodicallyOrContinuously"
                      />
                    </b-col>

                    <!-- repetitiveBehaviour -->
                    <b-col md="4">
                      <g-field
                        :value.sync="selectedItem.repetitiveBehaviour"
                        label-text="repetitiveBehaviour"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-tab>
            </b-tabs>

            <!-- save button -->
            <b-row
              v-if="currentStep === stepsEnum.studentSection"
            >
              <b-col
                cols="12"
                class="d-flex justify-content-end"
              >
                <!-- @click="tabIndex === studentInfoEnum.healthData ? save() : tabIndex ++"  -->
                <b-button
                  class="mx-1"
                  type="submit"
                  variant="relief-primary"
                >
                  {{ tabIndex === studentInfoEnum.healthData ? $t("sendRegisterationRequest") : $t("next") }}
                  <feather-icon
                    :icon="tabIndex === studentInfoEnum.healthData ? 'SendIcon' : 'ChevronLeftIcon'"
                    class="ml-25"
                  />
                </b-button>
              </b-col>
            </b-row>

            <!-- next and previous step buttons -->
            <b-row
              class="center-element mt-2"
            >
              <b-button
                variant="outline-primary"
                :disabled="currentStep === stepsEnum.guardianSection"
                @click="goToPreviousStep()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ArrowRightIcon"
                />
                {{ $t("previousStep") }}
              </b-button>
              <b-button
                class="ml-1"
                variant="outline-primary"
                :disabled="currentStep === stepsEnum.studentSection || (currentStep === stepsEnum.branchSection && !selectedItem.branchId)"
                @click="goToNextStep()"
              >
                {{ $t("nextStep") }}
                <feather-icon
                  class="ml-50"
                  icon="ArrowLeftIcon"
                />
              </b-button>
            </b-row>
          </g-form>

          <b-row
            v-if="formSubmitedSuccessfully"
            class="center-element flex-column"
          >
            <img
              :src="confirmedImg"
              width="300px"
              alt="SavedSuccessfully"
            >
            <label
              style="font-size: 18px;"
            > {{ $t('registerationRequestSentSuccessfully') }} </label>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  guardianRelationships,
  genders, childLivesWith,
  roleOfGrandparents, illnessHistoryInFamily,
  suffersFromAnAllergy, describeChild,
  busSubscriptionCategory
} from '@/libs/acl/Lookups';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  data() {
    return {
      clientBranches: [],
      nationalities: [],
      religions: [],
      maritalStatus: [],
      rows: [],
      stages: [],
      formSubmitedSuccessfully: false,
      selectedItem: {
        arabicName: '',
        englishName: '',
        notes: '',
        stageId: null,
        rowId: null,
        hasBus: false,
        status: 1,
        registeredInNoor: false,
        contactWithMobile: true
      },
      guardianRelationships: guardianRelationships,
      genders: genders,
      childLivesWith: childLivesWith,
      roleOfGrandparents: roleOfGrandparents,
      describeChild: describeChild,
      illnessHistoryInFamily: illnessHistoryInFamily,
      suffersFromAnAllergy: suffersFromAnAllergy,
      busSubscriptionCategory: busSubscriptionCategory,
      currentStep: 1,
      stepsEnum: {
        guardianSection: 1,
        branchSection: 2,
        studentSection: 3
      },
      tabIndex: 0,
      studentInfoEnum: {
        personalData: 0,
        educationalData: 1,
        healthData: 2
      },
      helper: {
        guardianMobile: null,
        guardianEmail: null
      }

    };
  },
  computed: {
    optionsRadio() {
      return [
        { text: this.$t('yes'), value: true },
        { text: this.$t('no'), value: false },
      ];
    },

    optionsContantMethods() {
      return [
        { text: this.$t('mobile'), value: true },
        { text: this.$t('email'), value: false },
      ];
    },

    backImg() {
      return `url(${require('@/assets/images/background/RegisterationForm-bg.jpg')})`
    },

    confirmedImg() {
      return require('@/assets/images/background/confirmed-bg.jpg')
    },

    clientDomain() {
      return document.location.host.split('.')[0]
    },
  },

  watch: {
    'selectedItem.contactWithMobile'() {
      this.helper.guardianMobile = null;
      this.helper.guardianEmail = null;
    },

    'selectedItem.stageId'() {
      this.selectedItem.rowId = null;
    }
  },

  mounted() {
    this.selectedItem.registrationDate = this.today;
    this.getBranches();
  },

  methods: {
    getBranches() {
      this.get({ url: `RegistrationRequests/getBranchesForClient?domain=${this.clientDomain}` }).then((data) => {
        this.clientBranches = data;
      });
    },

    loadData() {
      this.get({ url: `Nationalities/getNationalitiesForRegistration?domain=${this.clientDomain}&branchId=${this.selectedItem.branchId}` }).then((data) => {
        this.nationalities = data;
      });
      this.get({ url: `Stages/getStagesForRegistration?domain=${this.clientDomain}&branchId=${this.selectedItem.branchId}` }).then((data) => {
        this.stages = data;
      });
      this.get({ url: `Rows/getRowsForRegistration?domain=${this.clientDomain}&branchId=${this.selectedItem.branchId}` }).then((data) => {
        this.rows = data;
      });
      this.get({ url: `Religions/getReligionsForRegistration?domain=${this.clientDomain}&branchId=${this.selectedItem.branchId}` }).then((data) => {
        this.religions = data;
      });
      this.get({ url: `MaritalStatuses/getMartialStatusesForRegistration?domain=${this.clientDomain}&branchId=${this.selectedItem.branchId}` }).then((data) => {
        this.maritalStatus = data;
      });
    },

    isAllGuardianDataFilled() {
      if (!this.selectedItem.guardianName) return false;
      if (!this.helper.guardianMobile && !this.helper.guardianEmail) return false;
      if (this.helper.guardianMobile && this.helper.guardianMobile.length !== 10) return false;
      if (this.helper.guardianEmail && !this.helper.guardianEmail.includes('@')) return false;
      return true
    },

    goToNextStep() {
      if (!this.isAllGuardianDataFilled()) return;
      this.currentStep += 1;
      if (this.currentStep === this.stepsEnum.studentSection) {
        this.tabIndex = this.studentInfoEnum.personalData;
        this.loadData();
      }
    },

    goToPreviousStep() {
      this.currentStep -= 1;
    },

    registrationDateCheck() {
      if (!this.selectedItem.registrationDate) {
        this.doneAlert({ text: this.$t('registrationDateIsRequired'), type: 'warning' });
        return false;
      }
      return true;
    },

    birthDateCheck() {
      if (!this.selectedItem.birthDate) {
        this.doneAlert({ text: this.$t('birthDateIsRequired'), type: 'warning' });
        return false;
      }
      return true;
    },

    stagesCheck() {
      if (this.selectedItem.stageId === null || this.selectedItem.rowId === null) {
        this.doneAlert({ text: this.$t('stagesIsRequired'), type: 'warning' });
        return false;
      }
      return true;
    },

    prepareForAdd() {
      this.selectedItem.clientDomain = this.clientDomain
      if (this.selectedItem.noorId) this.selectedItem.registeredInNoor = true
      this.selectedItem.contactContent = this.selectedItem.contactWithMobile
                                       ? this.helper.guardianMobile
                                       : this.helper.guardianEmail
    },

    save() {
      if (this.tabIndex !== this.studentInfoEnum.healthData) {
        this.tabIndex += 1;
        return;
      }

      if (!this.birthDateCheck()) return;
      if (!this.registrationDateCheck()) return;
      if (!this.stagesCheck()) return;

      this.prepareForAdd();

      this.create({
        url: 'RegistrationRequests',
        data: this.selectedItem,
      }).then(() => {
        this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('registerationRequestSentSuccessfully'),
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.formSubmitedSuccessfully = true;
      });
    },
  }
};
</script>

<style>
.reg-form {
  min-height: 100vh;
  background-size: 250px;
}
.reg-form legend,
.reg-form a,
.reg-form label,
.reg-form span {
  letter-spacing: 1.3px;
  color: black;
}
.reg-form input,
.reg-form small,
.reg-form textarea,
.reg-form button {
  letter-spacing: 1px;
}
.reg-form input,
.reg-form textarea {
  color: rgb(104, 102, 102);
}
.reg-form .nav-link.active {
  background: #7367f0 !important;
}

.reg-form .nav-link{
  border-color: #7367f0;
  margin: 3px;
}

.reg-form .header {
  background-color: #7367f0;
  padding: 20px;
  border-radius: 5px;
  color: #FFF;
  font-size: 33px;
  letter-spacing: 2px;
  text-align: center;
  box-shadow: 0 0 3px 0 #5e7b85;
}

.reg-form .active-step,
.reg-form .nonActive-step {
  height: 40px;
  letter-spacing: 1.2px;
  text-align: center;
  padding: 10px 10px 0;
  border: 1px solid #7367f0;
  border-radius: 5px;
  cursor: default;
}

.reg-form .active-step {
  background-color: #7367f0;
  color: #FFF;
}

.reg-form .nonActive-step {
  background-color: #FFF;
  color: #7367f0;
}
.reg-form .reg-card {
  box-shadow: 0 0 3px 0 #5e7b85 !important;
}
.reg-form .reg-steps span {
  color: #7367f0;
  width: 100px;
  border: dashed 1px;
}
.reg-form .guardian-hint {
  font-size: 11px;
  color: red;
  display: block;
  margin-bottom: 15px;
  margin-top: -7px;
}

/* in large screens  */
@media (max-width: 1400px) {
  .reg-form {
    background-image: none;
  }
  .reg-form .reg-steps {
    flex-direction: column;
  }
  .reg-form .reg-steps span {
    width: 1px;
    height: 30px;
  }
}
</style>
